import classNames from 'classnames';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Page } from '../../constant/setting';
import { useTouch } from '../../hooks/useTouch';
import './index.less';

export interface ViewerProps {
  items: Page[];
  defaultIndex?: number;
  likeUrls: string[];
  onLike: (viewItem: Page) => void;
  onBack: () => void;
  ratio: number;
}

function Viewer(props: ViewerProps) {
  const { ratio, defaultIndex, items, likeUrls, onLike, onBack } = props;

  const [params] = useSearchParams();
  const [pageIndex, setPageIndex] = useState(() => defaultIndex || 0);
  const [offsetX, setOffsetX] = useState(0);
  const [isTransition, setIsTransition] = useState(false);
  const videoMapRef = useRef(new Map<string, HTMLVideoElement | null>());
  const playingVideoDomRef = useRef<HTMLVideoElement | null>();
  const [playingLike, setPlayingLike] = useState(false);
  const [likeSrcIndex, setLikeIndex] = useState(1);
  const likeSrc = useMemo(
    () =>
      `/images/icon_good_2_${
        likeSrcIndex > 9 ? likeSrcIndex : '0' + likeSrcIndex
      }.png`,
    [likeSrcIndex]
  );
  const playLikeTimerRef = useRef<NodeJS.Timer>();
  const pageName = useMemo(() => items[pageIndex].name, [items, pageIndex]);

  useEffect(() => {
    TDAPP.onEvent('Page', pageName, { name: pageName });
  }, [pageName]);

  const isLike = useMemo(() => {
    return likeUrls.includes(items[pageIndex].url);
  }, [items, pageIndex, likeUrls]);

  useTouch({
    container: document.getElementById('banner-area') as HTMLDivElement,
    distance: window.innerWidth / 6,
    onSwipping: (distanceX) => {
      setIsTransition(false);

      if (
        (pageIndex === 0 && distanceX > 0) ||
        (pageIndex === items.length - 1 && distanceX < 0)
      ) {
        return;
      }
      setTimeout(() => {
        setOffsetX(-pageIndex * window.innerWidth + distanceX);
      }, 0);
    },
    onSwip: (dir) => {
      setIsTransition(true);
      switch (dir) {
        case 'none':
          setTimeout(() => {
            setOffsetX(-pageIndex * window.innerWidth);
          }, 0);
          break;
        case 'left':
          setPageIndex((index) => (index - 1 < 0 ? 0 : index - 1));
          break;
        case 'right':
          setPageIndex((index) =>
            index + 1 >= items.length ? index : index + 1
          );
          break;
        default:
          break;
      }
    },
  });

  useEffect(() => {
    setTimeout(() => {
      setOffsetX(-pageIndex * window.innerWidth);
    }, 0);
  }, [pageIndex]);

  useEffect(() => {
    if (playingVideoDomRef.current) {
      playingVideoDomRef.current.pause();
    }
    setPlayingLike(false);
    playLikeTimerRef.current && clearInterval(playLikeTimerRef.current);
    setLikeIndex(1);
  }, [pageIndex]);

  function setVideoRef(videoDom: HTMLVideoElement | null, videoUrl: string) {
    videoMapRef.current.set(videoUrl, videoDom);
  }

  function playVideo(videoUrl: string) {
    const videoDom = videoMapRef.current.get(videoUrl);
    if (videoDom) {
      if (videoDom.paused) {
        videoDom.play();
        playingVideoDomRef.current = videoDom;
        TDAPP.onEvent('Video', pageName, { name: pageName });
      } else {
        videoDom.pause();
        playingVideoDomRef.current = null;
      }
    }
  }

  function onLinkClick(viewItem: Page) {
    TDAPP.onEvent('Link', viewItem.name, { name: viewItem.name });
  }

  function playLike() {
    setPlayingLike(true);
    playLikeTimerRef.current = setInterval(() => {
      setLikeIndex((index) => {
        if (index >= 24) {
          setPlayingLike(false);
          clearInterval(playLikeTimerRef.current);
          return index;
        }

        return index + 1;
      });
    }, 1000 / 12);
  }

  return (
    <div id="banner-area">
      <div
        id="banners-img"
        style={{
          width: `${items.length * 100}vw`,
          transform: `translateX(${offsetX}px)`,
          transition: isTransition ? 'all 0.3s' : 'none',
        }}
      >
        {items.map(
          (viewItem, index) =>
            Math.abs(pageIndex - index) <= 2 && (
              <div
                key={viewItem.url}
                className="img-area"
                style={{ left: `${index * 100}vw` }}
              >
                <div
                  className="img-wrap"
                  style={{
                    width: `${ratio * 100}vh`,
                    left: `calc(50vw - ${ratio * 50}vh)`,
                  }}
                >
                  <img src={viewItem.url} alt="" />
                  {viewItem.link && (
                    // eslint-disable-next-line jsx-a11y/anchor-has-content
                    <a
                      href={viewItem.link.url}
                      className={classNames('link-area', {
                        border: params.get('test'),
                      })}
                      style={{
                        ...viewItem.link.pos,
                      }}
                      onClick={() => onLinkClick(viewItem)}
                    ></a>
                  )}
                  {viewItem.video && (
                    <div
                      className={classNames('video-area', {
                        border: params.get('test'),
                      })}
                      style={{
                        ...viewItem.video.pos,
                      }}
                    >
                      <video
                        controls
                        className="video"
                        poster={viewItem.video.cover}
                        src={viewItem.video.url}
                        ref={(dom) =>
                          setVideoRef(
                            dom,
                            viewItem.video ? viewItem.video.url : ''
                          )
                        }
                      ></video>
                      <img
                        src={viewItem.video.cover}
                        alt=""
                        onClick={() =>
                          playVideo(viewItem.video ? viewItem.video.url : '')
                        }
                      ></img>
                    </div>
                  )}
                </div>
              </div>
            )
        )}
      </div>
      <img
        onClick={onBack}
        className="btn-back"
        src="/images/icon_back.png"
        alt=""
      />
      <div className="like-area">
        {pageIndex !== items.length - 1 && (
          <>
            <img
              className="like-img"
              alt=""
              src={
                playingLike
                  ? likeSrc
                  : isLike
                  ? '/images/icon_good_3.png'
                  : '/images/icon_good_1.png'
              }
            />
            <div
              className="touch-area"
              onTouchStart={() => !isLike && playLike()}
              onClick={() => !isLike && onLike(items[pageIndex])}
            ></div>
          </>
        )}
      </div>
    </div>
  );
}

export default Viewer;
