import React, { useEffect, useState } from 'react';
import { HotPointer, Page } from '../../constant/setting';
import Viewer from '../viewer';
import Progress from '../../components/progress';

import './index.less';
import { loadImages } from '../../utils/image';
import { useTouch } from '../../hooks/useTouch';
import classNames from 'classNames';
import { useSearchParams } from 'react-router-dom';

function Home() {
  const [likeUrls, setLikeUrls] = useState(() => {
    const likeUrlStr = localStorage.getItem('gallery-like');
    return likeUrlStr ? JSON.parse(likeUrlStr) : [];
  });

  const [viewerVisible, setViewerVisible] = useState(false);
  const [pageIndex, setPageIndex] = useState(0);
  const [pages, setPages] = useState<Page[]>([]);
  const [ratio, setRatio] = useState(790 / 1252);
  const [hotPointers, setHotPointers] = useState<HotPointer[]>([]);
  const [loadingPercent, setLoadingPercent] = useState(0);
  const [index, setIndex] = useState(0);
  const [offsetX, setOffsetX] = useState(0);

  const [params] = useSearchParams();

  useEffect(() => {
    document.body.addEventListener(
      'touchmove',
      (e) => {
        e.preventDefault();
      },
      { passive: false }
    );
  }, []);

  // useEffect(() => {
  //   ($('#flipbook') as any).turn({
  //     width: window.innerWidth,
  //     height: window.innerHeight,
  //     autoCenter: false,
  //     display: 'single',
  //   });
  //   console.log('1');
  // }, []);

  useEffect(() => {
    const imgs = [
      '/images/homepage_v4.png',
      '/images/foreword_1_v4.png',
      '/images/foreword_2_v4.png',
      '/images/0_v4.gif',
    ];
    const imgSrcs = pages.map((page) => page.url);
    loadImages([...imgs, ...imgSrcs], (percent) => {
      setLoadingPercent(percent);
    });
  }, [pages]);

  useEffect(() => {
    fetch('/setting.json')
      .then((response) => response.json())
      .then((setting) => {
        setPages(setting.pages);
        setRatio(setting.pageRatio);
        setHotPointers(setting.hotPointers);
      });
  }, []);

  function showViewer(index: number) {
    setPageIndex(index);
    setViewerVisible(true);
  }

  function onLike(viewItem: Page) {
    const { url: likeUrl, name } = viewItem;
    localStorage.setItem(
      'gallery-like',
      JSON.stringify([...likeUrls, likeUrl])
    );
    setLikeUrls([...likeUrls, likeUrl]);
    TDAPP.onEvent('Like', name, { pageName: name });
  }

  useTouch({
    container: document.getElementById('flipbook') as HTMLDivElement,
    distance: window.innerWidth / 6,
    onSwipping: (distanceX) => {
      if ((index === 0 && distanceX > 0) || (index === 1 && distanceX < 0)) {
        return;
      }
      setOffsetX(-index * window.innerWidth + distanceX);
    },
    onSwip: (dir) => {
      switch (dir) {
        case 'none':
          setTimeout(() => {
            setOffsetX(-index * window.innerWidth);
          }, 0);
          break;
        case 'left':
          setIndex((index) => (index - 1 < 0 ? 0 : index - 1));
          break;
        case 'right':
          if (index + 1 >= 4) {
            setOffsetX(-index * window.innerWidth);
            showViewer(0);
          } else {
            setIndex((index) => index + 1);
          }
          break;
        default:
          break;
      }
    },
  });

  useEffect(() => {
    setTimeout(() => {
      setOffsetX(-index * window.innerWidth);
    }, 0);

    const pageNames = ['Home', 'foreword_1', 'foreword_2', 'Menu'];

    TDAPP.onEvent('Page', pageNames[index], {
      name: pageNames[index],
    });
  }, [index]);

  function onHotPointClick(hotPoint: HotPointer) {
    TDAPP.onEvent('Menu', hotPoint.name, { name: hotPoint.name });
    showViewer(hotPoint.pageIndex);
  }

  return (
    <div className="home">
      <div
        id="flipbook"
        className="flipbook"
        style={{
          transform: `translateX(${offsetX}px)`,
          ...(viewerVisible ? { display: 'none' } : {}),
        }}
      >
        <div className="book-page">
          <div className="menu-wrap">
            <img className="menu" src="/images/homepage_v4.png" alt="" />
          </div>
        </div>
        <div className="book-page">
          <div className="menu-wrap">
            <img className="menu" src="/images/foreword_1_v4.png" alt="" />
          </div>
        </div>
        <div className="book-page">
          <div className="menu-wrap">
            <img className="menu" src="/images/foreword_2_v4.png" alt="" />
          </div>
        </div>
        <div className="book-page">
          <div className="menu-wrap">
            {index === 3 && !viewerVisible && (
              <img className="menu" src="/images/0_v4.gif" alt="" />
            )}
            <div className="hot-pointers">
              {hotPointers.map((hotPoint) => (
                <div
                  key={hotPoint.pageIndex}
                  onClick={() => onHotPointClick(hotPoint)}
                  className={classNames('hot-pointer', {
                    border: params.get('test'),
                  })}
                  style={hotPoint.pos}
                ></div>
              ))}
            </div>
          </div>
        </div>
      </div>

      {viewerVisible && (
        <Viewer
          ratio={ratio}
          items={pages}
          likeUrls={likeUrls}
          defaultIndex={pageIndex}
          onLike={onLike}
          onBack={() => setViewerVisible(false)}
        ></Viewer>
      )}
      {loadingPercent < 100 && (
        <div className="mask">
          <div className="progress">
            <Progress
              percent={loadingPercent}
              strokeColor="#fff"
              trailColor="transparent"
            ></Progress>
            <div className="progress-text">{loadingPercent.toFixed(2)}%</div>
          </div>
        </div>
      )}
      {index === 0 && (
        <img className="hometip" src="/images/hometip.gif" alt="" />
      )}
    </div>
  );
}

export default Home;
