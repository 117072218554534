import React from 'react';
import './index.less';

export interface ProgressProps {
  percent: number;
  strokeColor: string;
  trailColor: string;
  style?: React.CSSProperties;
}

const Progress = (props: ProgressProps) => {
  const { percent, strokeColor, trailColor } = props;

  return (
    <div className="progress-wrap" style={{ backgroundColor: trailColor }}>
      <div
        className="progress-inner"
        style={{ width: `${percent}%`, backgroundColor: strokeColor }}
      ></div>
    </div>
  );
};

export default Progress;
