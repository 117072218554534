import { RouteObject } from 'react-router-dom';
import { lazyLoad } from './lazy-load';
import Home from '../pages/home';

const routes: RouteObject[] = [
  {
    path: '',
    children: [
      {
        path: '/',
        element: lazyLoad(<Home />),
      },
    ],
  },
];
export default routes;
