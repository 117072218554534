import React from 'react';
import './App.css';
import { useRoutes } from 'react-router';
import routers from './router';

function App() {
  return <div className="App">{useRoutes(routers)}</div>;
}

export default App;
