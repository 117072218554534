export function loadImage(src: string) {
  const img = new Image();

  return new Promise((resolve, reject) => {
    img.onload = (e) => {
      resolve(e);
    };

    img.onerror = (e) => resolve(e);

    img.src = src;
  });
}

export function loadImages(
  srcs: string[],
  onProcess?: (percent: number) => void
) {
  let loadedCount = 0;
  return new Promise((resolve, reject) => {
    srcs.forEach((src) => {
      loadImage(src).then(() => {
        loadedCount++;
        onProcess && onProcess((loadedCount * 100) / srcs.length);
      });
      if (loadedCount === srcs.length) {
        resolve(true);
      }
    });
  });
}
