import { useEffect, useRef, useState } from 'react';

export type TouchMoveDirection = 'left' | 'right' | 'none';

export interface UseTouchProps {
  container: HTMLElement;
  distance: number;
  onSwipping?: (distanceX: number) => void;
  onSwip?: (dir: TouchMoveDirection) => void;
}

export function useTouch(props: UseTouchProps) {
  const { container, distance, onSwipping, onSwip } = props;

  const posRef = useRef({
    startX: 0,
    distanceX: 0,
    isMove: false,
  });
  const [offsetX, setOffsetX] = useState(0);

  useEffect(() => {
    if (!container) {
      return;
    }

    function onTouchStart(e: TouchEvent) {
      //   e.preventDefault();
      posRef.current.isMove = true;
      posRef.current.startX = e.touches[0].clientX;
      posRef.current.distanceX = 0;
    }

    function onTouchMove(e: TouchEvent) {
      e.preventDefault();
      const moveX = e.touches[0].clientX;
      posRef.current.distanceX = moveX - posRef.current.startX;
      setOffsetX(posRef.current.distanceX);
      onSwipping && onSwipping(posRef.current.distanceX);
    }

    function onTouchEnd() {
      if (
        posRef.current.isMove &&
        Math.abs(posRef.current.distanceX) > distance
      ) {
        onSwip && onSwip(posRef.current.distanceX > 0 ? 'left' : 'right');
      } else {
        onSwip && onSwip('none');

        posRef.current.startX = 0;
        posRef.current.distanceX = 0;
        posRef.current.isMove = false;
      }
    }

    container.addEventListener('touchstart', onTouchStart);
    container.addEventListener('touchmove', onTouchMove);
    container.addEventListener('touchend', onTouchEnd);
    container.addEventListener('touchcancel', onTouchEnd);

    return () => {
      container.removeEventListener('touchstart', onTouchStart);
      container.removeEventListener('touchmove', onTouchMove);
      container.removeEventListener('touchend', onTouchEnd);
      container.removeEventListener('touchcancel', onTouchEnd);
    };
  }, [container, distance, onSwipping, onSwip]);

  return {
    offsetX,
  };
}
